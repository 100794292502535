import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/base/layout"
import Hero from "../components/section/hero"
import Article from "../components/section/article";

export default function Home() {
  const data = useStaticQuery(query);
  return (
    <Layout seo={data.strapiHome.seo} slug={data.strapiHome.slug_fr} lang="fr" currentId="1">
      <Hero title={data.strapiHome.hero.title_fr} />
      <Article list={data.strapiHome.article} lang="fr" />
    </Layout>
  );
}

const query = graphql`
  query {
    strapiHome {
      slug_fr
      seo {
        meta_title_fr
        meta_description_fr
      }
      hero {
        title_fr
      }
      article {
        type
        subtext_fr
        text_fr
        button {
          label
          url
        }
      }
    }
  }
`;